<template>
  <section class="construction-method">
    <header class="hero-small">
      <div>
        <h1>Des maisons au mode constructif <br/> bien pensé</h1>
      </div>
    </header>
    <main>
      <h3>Notre catalogue de maisons est conçu à la base pour vous offrir les meilleures solutions techniques aux meilleurs coûts. C’est un avantage indéniable en termes de budget et de fiabilité mais ne supposez pas que c'est au détriment de votre capacité à personnaliser votre projet. C’est pourquoi, le configurateur Maisons Alysia vous permet de trouver votre bonheur en vous offrant le choix parmi de nombreuses options.</h3>
      <div class="solutions-section">
        <div class="grid-container">
          <img src="~@/assets/img/construction-icon.png" alt=""/>
          <h2>L’optimisation des ouvrages et fournitures « de série »</h2>
          <p>Notre équipe technique et notre bureau d’étude a conçu pour vous un système de construction optimisé. Vous bénéficiez de <router-link :to="{ name: 'brick-or-cinder-block' }">matériaux</router-link> et d’équipements de qualité pour plus de fiabilité et de confort. Nous vous garantissons la performance énergétique (respect de la norme <router-link :to="{ name: 'positive-house' }">RE2020</router-link>). Et tout ça, en maîtrisant les coûts. Découvrez nos solutions plus en détail :</p>
          <aside class="link-container">
            <ul class="grid-x grid-margin-x grid-margin-y">
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-sanitaires-et-chauffage' }"><img src="~@/assets/img/bathtub-icon.svg" alt=""/>Sanitaires &#38; chauffage</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-gros-oeuvre' }"><img src="~@/assets/img/building-wall-icon.svg" alt=""/>Gros oeuvre</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-sols-et-revetements' }"><img src="~@/assets/img/floor-icon.svg" alt=""/>Sols &#38; Revêtements</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-toiture' }"><img src="~@/assets/img/roof-icon.svg" alt=""/>Toiture</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-divers' }"><img src="~@/assets/img/file-icon.svg" alt=""/>Divers</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-menuiseries' }"><img src="~@/assets/img/door-icon.svg" alt=""/>Menuiseries</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-autres-amenagements' }"><img src="~@/assets/img/closet-doors-icon.svg" alt=""/>Autres aménagements</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-isolation-et-plaquisterie' }"><img src="~@/assets/img/isolation-icon.svg" alt=""/>Isolation &#38; Plaquisterie</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-regles-geographiques' }"><img src="~@/assets/img/france-icon.svg" alt=""/>Règles géographiques</router-link></li>
              <li class="cell medium-12 large-6"><router-link :to="{ name: 'category-electricite' }"><img src="~@/assets/img/electrical-plug-icon.svg" alt=""/>Électricité</router-link></li>
            </ul>
          </aside>
        </div>
      </div>
      <div class="grid-container">
        <card-type-img title="La personnalisation de vos équipements et finitions">
          <template v-slot:content>
            <p>Grâce au configurateur Maisons Alysia, faîtes-vous plaisir à travers une expérience décisionnelle unique : composez à la carte les options de votre projet sur toutes les catégories du mode constructif,  ses équipements et même ses finitions (menuiseries, isolation, salle de bains, revêtements, placards, design et finitions extérieures…).</p>
            <p>Chacun de vos choix optionnels impacte automatiquement le prix total du projet dans le configurateur et chaque alternative est expliquée et détaillée dans le descriptif.</p>
            <p>Alors, cochez et décochez à loisir !</p>
          </template>
          <template v-slot:img>
            <img src="~@/assets/img/pages/content-option-2.png" alt="" />
          </template>
        </card-type-img>
        <div class="grid-container packs-container">
          <h2>Un confort de configuration fondé sur 3 niveaux de finitions</h2>
          <p>Nous avons pré-sélectionné 3 configurations types pour vous faire gagner encore plus de temps. Une fois sélectionné, vous avez encore le choix de <b>modifier les options de votre pack </b>: il n’a jamais été aussi facile de définir son projet de construction.</p>
          <div v-for="pack in packs" :key="pack.label" class="card-house-models" :class="pack.class">
            <div class="label">
              <h3>PACK</h3>
              <h3>{{ pack.label }}</h3>
              <hr />
            </div>
            <div>
              <h3>{{ pack.title }}</h3>
              <p v-html="pack.description"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="partners-container">
        <div class="grid-container">
          <h2>La performance de nos partenaires industriels</h2>
          <p>Maisons Alysia a noué et développé des partenariats avec des fabricants industriels de tout premier plan au niveau national. Que ce soit pour les matériaux, les équipements, les ouvertures, les menuiseries ou les dispositifs de chauffage, la qualité est un critère fondateur de notre cahier des charges. Des industriels aux marques connues, éprouvées et reconnues. Jugez-en par vous même…</p>
          <div class="grid-x grid-margin-x grid-margin-y align-center align-middle">
            <img v-for="partner in partners" :key="partner.name" :src="require(`@/assets/img/pages/partners/${partner.logo}`)" :alt="partner.name" class="cell"/>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  components: {
    CardTypeImg,
  },
  data() {
    return {
      packs: [
        {
          label: 'Essentiel',
          title: 'La configuration standard Alysia',
          description: 'Le Pack Essentiel correspond à la <b>configuration de base</b> des maisons Alysia. Ce sont les ouvrages, finitions et prix <b>affichés par défaut dans notre catalogue de maisons.</b> Quand vous choisissez ce pack vous avez accès à de nombreuses options.',
          class: 'essential-pack',
        }, {
          label: 'Eko',
          title: 'L’optimisation économique',
          description: '<b>Faites de sérieuses économies</b> grâce au pack EKO. Vous réalisez vous-même certaines prestations comme par exemple les revêtements des sols. Vous avez accès à <b>moins d\'options pour optimiser votre budget.</b>',
          class: 'eko-pack',
        }, {
          label: 'Premium',
          title: 'Le meilleur des options',
          description: 'Portes intérieures Design, revêtement de sols dans les chambres, carrelage 60 x 60 cm… le Pack Premium sélectionne pour vous nos meilleures options. <b>Vous accèdez à toutes les options du configurateur.</b>',
          class: 'premium-pack',
        },
      ],
      partners: [
        { name: 'Actis', logo: 'actis.png' },
        { name: 'Atlantic', logo: 'atlantic.png' },
        { name: 'AVM', logo: 'avm.png' },
        { name: 'Bouyer Leroux', logo: 'bouyer-leroux.png' },
        { name: 'Chaffoteaux', logo: 'chaffoteaux.png' },
        { name: 'EBPSN', logo: 'ebpsn.png' },
        { name: 'Hitachi', logo: 'hitachi.png' },
        { name: 'Kp1', logo: 'kp1.png' },
        { name: 'Monier', logo: 'monier.png' },
        { name: 'Point P', logo: 'point-p.png' },
        { name: 'PRB', logo: 'prb.png' },
        { name: 'Rector', logo: 'rector.png' },
        { name: 'Schneider Electric', logo: 'schneider-electric.png' },
        { name: 'Soprema', logo: 'soprema.png' },
        { name: 'SWAO', logo: 'swao.png' },
        { name: 'Terreal', logo: 'terreal.png' },
        { name: 'Unilin', logo: 'unilin.png' },
        { name: 'VM Matériaux', logo: 'vm-materiaux.png' },
      ],
    };
  },
};

</script>

<style lang="sass">
.construction-method
  background-color: $medium-bg
  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/little-child-sitting-floor.png)
  main
    text-align: center
    background-color: $medium-bg
    padding-top: 3rem
    h3
      width: 80%
      margin: 3rem auto
    a
      text-decoration: none
      font-weight: 700
      color: $primary
    .solutions-section
      background-color: $white
      // width: 100vw
      position: relative
      padding-bottom: 200px
      margin-bottom: 250px
      .grid-container
        padding-top: 3rem
        padding-bottom: 3rem
        > img
          width: 73px
          height: 73px
        > h2
          margin: 3rem auto
        > p
          padding: 0 10%
        .link-container
          position: absolute
          background-color: $colored-bg
          text-align: left
          width: 800px
          margin: auto
          border-radius: $global-border-radius
          bottom: 0
          left: 0
          right: 0
          transform: translateY(50%)
          padding: 2rem 0
          li
            img
              width: 32px
              height: 32px
              margin-right: 2rem
              vertical-align: text-bottom
            a
              font-weight: initial
              color: $white
              text-decoration: underline
              font-size: 20px
              line-height: 28px
  .card-type-img
    text-align: left
    p
      margin: 25px 0
  .packs-container
    margin: 6rem auto
    max-width: 937px
    > p
      margin: 2rem 0 4rem 0
    .card-house-models
      @include card-house-models
      height: fit-content
      margin: 1rem 0
      padding: 3rem 3rem
      &.essential-pack
        --main-color: #{$primary}
      &.eko-pack
        --main-color: #{$warning}
      &.premium-pack
        --main-color: #F1623E
      .label
        width: 40%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        h3
          text-transform: uppercase
          margin: 4px auto
          &:last-of-type
            font-size: 48px
        hr
          margin-top: 10px
          width: 72px
          height: 9px
          background-color: var(--main-color)
          border: none
      div:last-child
        padding-right: 0
        padding-left: 60px
        text-align: left
        h3
          color: var(--main-color)
  .partners-container
    background-color: $white
    padding: 3rem 0
    .grid-container
      > p
        margin: 2rem 0
        color: $subtitle
      .grid-x
        margin-left: 0
        margin-right: 0
        img
          width: 200px

  @media (max-width: 768px)
    main
      .solutions-section
        padding-bottom: 60px
        margin-bottom: 670px
        .grid-container
          .link-container
            width: 90%
            transform: translateY(90%)
            li
              margin: 1rem 0
        @media (max-width: 360px)
          margin-bottom: 700px
        @media (max-width: 340px)
          margin-bottom: 770px
      .packs-container
        .card-house-models
          padding: 1rem 0
          .label
            width: 100%
            height: fit-content
            padding: 2rem 0
          div:last-child
            padding-right: 2rem
            padding-left: 2rem
            text-align: center
            h3
              margin-left: auto
              margin-right: auto
      .partners-container
        .grid-container
          p
            padding-left: 2rem
            padding-right: 2rem
</style>
